// src/auth/FireBase.tsx
import React, {useEffect} from "react";
import {GoogleAuthProvider, EmailAuthProvider, getAuth, signInAnonymously} from "firebase/auth";
import {getAnalytics} from "firebase/analytics"
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBJCxbgZm6tPoOkGxGRjA0ez1SXeyfAyj0",
    authDomain: "stories-ai-b016d.firebaseapp.com",
    projectId: "stories-ai-b016d",
    storageBucket: "stories-ai-b016d.appspot.com",
    messagingSenderId: "859640948098",
    appId: "1:859640948098:web:47337fceb41965b4985b9b"
};

export const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);

export const FireBasePopup = ({ setShowAuth, successUrl }) => {
    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: successUrl,
        signInOptions: [
            GoogleAuthProvider.PROVIDER_ID,
            EmailAuthProvider.PROVIDER_ID
        ]
    }

    useEffect(() => {
        let ui = firebaseui.auth.AuthUI.getInstance();
        if (!ui) {
            ui = new firebaseui.auth.AuthUI(auth);
        }
        ui.start('#firebase-auth-buttons', uiConfig);
        return () => {
            if (ui) {
                ui.delete();
            }
        };
    }, []);

    const handleAnonymousSignIn = () => {
        const auth = getAuth();
        signInAnonymously(auth)
            .then(() => {
                console.log("Signed in anonymously");
            })
            .catch((error) => {
                console.error("Error during anonymous sign-in: ", error);
            });
    };

    return (
        <div className="firebase-auth-container">
            <div className="firebase-auth-window">
                <button className="auth-container-close" onClick={() => setShowAuth(false)}>X</button>
                <div id="firebase-auth-buttons"/>
                <ul className="firebaseui-idp-list" id={"anon-list"}>
                    <li className="firebaseui-list-item" id={"anon-list-item"}>
                        <button
                            className={"firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-anonymous firebaseui-id-idp-button"}
                            id={"anon-sign-in-btn"}
                            onClick={handleAnonymousSignIn}>Continue as Guest</button>
                    </li>
                </ul>

                <div className="firebaseui-card-footer firebaseui-provider-sign-in-footer">
                    <p className="firebaseui-tos firebaseui-tospp-full-message">
                        By continuing, you are indicating that you accept <br/> our <a href="javascript:void(0)" className="firebaseui-link firebaseui-tos-link" target="_blank">Terms of Service</a> and<a href="javascript:void(0)" className="firebaseui-link firebaseui-pp-link" target="_blank">Privacy Policy</a>.</p>
                </div>
            </div>
        </div>
    );
};

export async function saveUserToFirestore(user) {
    if (!user) return;

    const userRef = doc(db, "users", user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
        await setDoc(userRef, {
            uid: user.uid,
            email: user.email || null,
            createdAt: new Date(),
            stripeCustomerId: null,
        });
        console.log("✅ User added to Firestore:", user.uid);
    }
}