import React, {useEffect, useState} from 'react';
import './css/App.css';
import {WelcomeScreen} from "./pages/WelcomeScreen";
import {GlobalStateProvider} from "./contexts/GlobalStateContext";
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {GameView} from "./pages/GameView";
import {DashboardScreen} from "./pages/DashboardScreen";
import AccountSettings from "./pages/AccountSettings";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import LoadingScreen from "./pages/LoadingScreen";
import {checkPremiumStatusCall} from "./api/AccountCalls";
import {SavedGamesScreen} from "./pages/SavedGamesScreen";
import { Analytics } from "@vercel/analytics/react"
import {saveUserToFirestore, db} from "./auth/FireBase";
import { getDoc, doc } from "firebase/firestore";

function App() {
    const [user, setUser] = useState<any>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isPremiumUser, setIsPremiumUser] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                saveUserToFirestore(user);
                setUser(user);
                setIsAuthenticated(true);
                const checkPremiumStatus = async () => {
                    try {
                        const userRef = doc(db, "users", user.uid);
                        const userSnap = await getDoc(userRef);
                        if (userSnap.exists()) {
                            setIsPremiumUser(userSnap.data().isPremium || false);
                        }
                    } catch (error) {
                        console.error("Error checking premium status:", error);
                    }
                    setLoading(false);
                };
                checkPremiumStatus();
            } else {
                setIsAuthenticated(false);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [auth]);

    if (loading) {
        return (
            <div className="app-container">
                <LoadingScreen />
            </div>
        )
    }

    if (!isAuthenticated) {
        return (
            <div className="app-container">
                <WelcomeScreen isAuthenticated={isAuthenticated}/>
            </div>
        );
    }

    return (
        <Router>
            <Analytics />
            <GlobalStateProvider user={user} isPremiumUser={isPremiumUser}>
                <div className="app-container">
                    <Routes>
                        <Route path="/" element={<WelcomeScreen isAuthenticated={isAuthenticated} />}/>
                        <Route path="/dashboard" element={<DashboardScreen />}/>
                        <Route path="/game/:roomId" element={<GameView />}/>
                        <Route path="/account-settings" element={<AccountSettings />} />
                        <Route path="/saved-games" element={<SavedGamesScreen />}/>
                    </Routes>
                </div>
            </GlobalStateProvider>
        </Router>
    );
}

export default App;